import React from 'react';
import './style.scss';
import { t } from 'i18next';
import getCountryValue from '../../../../lib/utils/getCountryValue';

const CustomDiscount = ({type, value}) => {
    if (type === 'group') {
        return (
            <div className="discount discount__container-group d-flex justify-content-between">
                <div className="discount__text">
                    {t('subscriptions.discountGroupLabel')}
                </div>
                <div className="discount__amount">
                    <div>{`${value}%`}</div>
                    <div>{`${value}%`}</div>
                    <div>{`${value}%`}</div>
                    <div>{`${value}%`}</div>
                </div>
            </div>
        )
    } else if (type === 'keel') {
        return (
            <div className={`discount__container-${type}`}>
                <div className="discount__value">{`– ${value}%`}</div>
                <div className="discount__text" style={{ fontSize: getCountryValue({ en: 8, ru: 12 })}}>
                    {t('subscriptions.discountKilLabel')}
                </div>
            </div>
        )
    } else if (type === 'race') {
            return (
                <div className={`discount__container-${type}`}>
                    <div className="discount__value">{`– ${value}%`}</div>
                    <div className="discount__text">
                        {t('subscriptions.discountRaceLabel')}
                    </div>
                </div>
            )
    } else {
        return (
            <div className={`discount__container-${type}`}>
                <div className="discount__value">{`– ${value}%`}</div>
                <div className="discount__text">
                    {t('subscriptions.discountIndividualLabel')}
                </div>
            </div>
        ) 
    }

}

export default CustomDiscount;