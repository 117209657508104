import './style.scss';
import PropTypes from 'prop-types';
import {useForm} from "react-hook-form";
import PeopleForms from './PeopleForms';
import React, {useEffect, useState} from 'react';
import FormButton from '../../../../generic/buttons/main';
import {preparePeopleData} from "../../../../../lib/utils/helpers";
import {NumberParam, useQueryParams, withDefault} from "use-query-params";
import AgeConsent from "../../../../common/AgeConsent";
import DuplicateData from "../../DuplicateData";
import {handleInputChange} from "../../../../../lib/utils/handleInputChange";
import {useLoaderState} from "../../../../../lib/api/loaderState";
import {t} from "i18next";
import { useEventRecordsApi } from '../../../../../lib/api/eventRecords';
import { CarrotEventTypes } from 'sv-common/constants/carrotEventTypes';
import useOrder from './hooks/useOrder';

const MembersScreen = ({payingUser, peopleData, setPeopleData, selectedGroup}) => {
    const { record } = useEventRecordsApi();

    const [showPeopleForms, setShowPeopleForms] = useState(false);
    const togglePeopleForms = (e) => {
        const {value} = handleInputChange(e);
        setShowPeopleForms(() => !value);
    }
    const [serverError, setServerError] = useState('');

    const [query, setQuery] = useQueryParams({
        step: NumberParam,
        people: withDefault(NumberParam, 1),
    });

    const {register, errors, handleSubmit, getValues, setValue, control, setError} = useForm({
        mode: "onChange",
        reValidateMode: 'onChange'
    });

    const {setIsLoading, isLoading} = useLoaderState();
    const { submit } = useOrder(showPeopleForms, peopleData, query, selectedGroup, setIsLoading, setPeopleData, setError, setQuery, setServerError);

    let values = getValues();

    useEffect(() => {
        setPeopleData([payingUser, ...new Array(query.people-1).fill(undefined)]);
        selectedGroup.id && record(CarrotEventTypes.ON_STEP_2_EVENT, { peopleCount: query.people, group: true, ...selectedGroup });
    }, []);

    return (
        <form data-testid='members-form' className='members-form'
              onSubmit={handleSubmit(submit)}
        >
            <PeopleForms peopleData={peopleData} country={'russia'}
                         register={register} errors={errors}
                         values={values} bufferPeopleData={peopleData}
                         setValue={setValue} payingUser={payingUser}
                         control={control}
                         updatePeopleData={({target}) => {
                             setPeopleData(preparePeopleData(target.name, target.value, peopleData))
                         }}
                         showPeopleForms={showPeopleForms}
                         tgOptions={selectedGroup?.tg_options}
            />
            <DuplicateData register={register} peopleAmount={query.people} onChange={togglePeopleForms}/>
            {selectedGroup.checkAgeRequired &&
                <AgeConsent register={register} error={errors.ageConsent} value={values.ageConsent} required
                            age={selectedGroup.checkAgeValue}
                />
            }
            {!!serverError &&
                <p className="member">
                    {serverError}
                </p>
            }
            <FormButton data-testid='next-submit' type="submit" value={t('common.next')} disabled={isLoading || !!serverError}/>
        </form>
    )
}

MembersScreen.propTypes = {
    payingUser: PropTypes.object.isRequired,
    peopleData: PropTypes.array.isRequired,
    setPeopleData: PropTypes.func.isRequired,
    selectedGroup: PropTypes.object.isRequired
}

export default MembersScreen;