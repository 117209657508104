import PropTypes from 'prop-types';
import {normalizeCurrency} from "../../../lib/utils/helpers";
import {isRus} from "../../../lib/utils/country";
import { t } from 'i18next';

const getPrice = (v) => {
    if (v === undefined) {
        return '';
    }

    return v;
}

const PriceBlock = ({hasPaid, oldPrice, price, entity, currencies}) => {
    const paidEn = isRus ? '': 'paid_en'

    const currency = normalizeCurrency(entity?.price_currency, currencies) || '';

    return (
        <div className={hasPaid ? `price-info paid ${paidEn}` : "price-info"}>
            <div className="price-left-col">
                <div className="price-label card-label">{t('subscriptions.price')}</div>
                <div className="price-row">
                    {oldPrice > price && <div className="old-price">{getPrice(oldPrice)} {currency}</div>}
                    <div className="price">
                        {`${getPrice(price)} ${currency}`}
                    </div>
                </div>
            </div>
        </div>
    )
}


PriceBlock.propTypes = {
    order: PropTypes.object,
    entity: PropTypes.object,
    currencies: PropTypes.array,
    certificateApplied: PropTypes.oneOfType([
        PropTypes.instanceOf(null),
        PropTypes.object,
    ]),
}
export default PriceBlock