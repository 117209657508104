import { t } from 'i18next';
import dayjs from 'dayjs';
import { accusativePlaces } from '../../../../../../lib/utils/helpers';
import React from 'react';
import getCountryValue from '../../../../../../lib/utils/getCountryValue';

const CommonInfoLectures = () => {
  const emailValue = getCountryValue({ en: 'hello.dubai@windrises.com', ru: 'allo@silavetra.com' });

  return (<>
    <br/><br/>
    {t('final.soonWillEmailLecture')}
    <br/><br/>
    {t('final.ifHaveQuestions')}<a href={`mailto:${emailValue}`}>{emailValue}</a>
    <br/><br/>
  </>)
}

const useLectures = (setTitle, setUnpaidTitle, order) => {
  const handle = () => {
    if (order.groupId?.price === 0)
      setTitle(() => <>
        {t('final.successBookLecture')} <nobr> {dayjs(order.groupId?.startDate).format('DD.MM')}</nobr>.
        <CommonInfoLectures/>
      </>)
    else
      setTitle(() => <>
        {t('final.successPayLecture')} <nobr> {dayjs(order.groupId?.startDate).format('DD.MM')}</nobr>.
        <CommonInfoLectures/>
      </>)
    setUnpaidTitle(() => <>
        {t('final.thanksForBook', {order: order.participants?.length, places: accusativePlaces(order.participants?.length),
          orderName: 'на лекцию'})}
        <nobr>{dayjs(order.groupId?.startDate).format('DD.MM')}</nobr> {t('final.beingProcessing')}
        <CommonInfoLectures/>
      </>
    )
  }

  return { handle };
}

export default useLectures;