import { makeAutoObservable } from 'mobx';
import {
  ICertificate,
  ICurrency,
  IGroup,
  IPromocode,
  TOrder,
  TPromoAndCertDiscount,
} from '../../../../lib/utils/types';
import { DecodedValueMap, QueryParamConfigMap } from 'serialize-query-params';
import { SetQuery } from 'use-query-params/lib/types';
import { getCookie } from '../../../../lib/utils/cookies';
import { TUserFields } from '../../../../lib/api/useTypedPublicEndpoint';
import { injected, token } from 'brandi';
import { IOrdersService, OrdersServiceStoreToken } from '../../../../entities/orders/api/service';
import { IUsersService, UsersServiceStoreToken } from '../../../../entities/users/api/service';
import { CurrenciesServiceStoreToken, ICurrenciesService } from '../../../../entities/currencies/api/service';
import { AuthModelStoreToken, IAuthModel } from '../../../../entities/auth/model';
import container from '../../../../lib/ioc';

interface IInitialProps {
  query: DecodedValueMap<QueryParamConfigMap>,
  setQuery: SetQuery<QueryParamConfigMap>,
}

interface ISchoolContainerModel {
  order: TOrder,
  selectedGroup: IGroup,
  peopleData: { [key: string]: any }[],
  payingUser: TUserFields,
  currencies: ICurrency[],
  promoApplied: IPromocode,
  certApplied: ICertificate,
  promoAndCertDiscount: TPromoAndCertDiscount,
  serverError: string,

  initial: (props: IInitialProps) => void,
  setOrder: (order: TOrder) => void,
  setSelectedGroup: (group: IGroup) => void,
  setPeopleData: (peopleData: { [key: string]: any }[]) => void,
  setPayingUser: (user: TUserFields) => void,
  setCurrencies: (values: ICurrency[]) => void,
  setDefaultOrder: (orderId: number) => void,
  setPromoApplied: (value: IPromocode) => void,
  setCertApplied: (value: ICertificate) => void,
  setPromoAndCertDiscount: (value: TPromoAndCertDiscount) => void,
  changePayingUser: () => void,
  setServerError: (value: string) => void,
}

export default class SchoolContainerModel implements ISchoolContainerModel {
  private ordersS: IOrdersService;
  private userS: IUsersService;
  private currencyS: ICurrenciesService;
  private authM: IAuthModel;

  order: TOrder = {} as TOrder;
  selectedGroup: IGroup = {} as IGroup;
  peopleData: { [key: string]: any }[] = [];
  payingUser: TUserFields = {} as TUserFields;
  currencies: ICurrency[] = [];
  promoApplied: IPromocode = {} as IPromocode;
  certApplied: ICertificate = {} as ICertificate;
  promoAndCertDiscount: TPromoAndCertDiscount = {} as TPromoAndCertDiscount;
  serverError = '';

  constructor(ordersS: IOrdersService, userS: IUsersService, currencyS: ICurrenciesService, authM: IAuthModel) {
    this.ordersS = ordersS;
    this.userS = userS;
    this.currencyS = currencyS;
    this.authM = authM;

    makeAutoObservable(this, {}, {autoBind: true});
  }

  initial(props: IInitialProps) {
    !props.query.orderId && props.setQuery({step: 1});

    if (getCookie('sv_booking_email')) {
      this.userS.getCleanFields()
        .then(res => {
          this.setPayingUser(res)
        })
        .catch(e => console.log(e));
    }

    if (props.query.step && !this.authM.isLoggedIn()) {
      props.setQuery({step: 1, orderId: undefined});
    }

    this.currencyS.get().then(this.setCurrencies);
  }

  setOrder(order: TOrder) {
    this.order = order;
  }

  setSelectedGroup(group: IGroup) {
    this.selectedGroup = group;
  }

  setPeopleData(peopleData: { [key: string]: any }[]) {
    this.peopleData = peopleData;
  }

  setPayingUser(user: TUserFields) {
    this.payingUser = user;
  }

  setCurrencies(values: ICurrency[]) {
    this.currencies = values;
  }

  setDefaultOrder(orderId: number) {
    this.ordersS.get(orderId).then((res) => {
      this.setOrder(res);
      this.setSelectedGroup(res.groupId);
      this.setPeopleData(res.participants);
    });
  }

  setPromoApplied(value: IPromocode) {
    this.promoApplied = value;
  }

  setCertApplied(value: ICertificate) {
    this.certApplied = value;
  }

  setPromoAndCertDiscount(value: TPromoAndCertDiscount) {
    this.promoAndCertDiscount = value;
  }

  changePayingUser() {
    this.userS.getCleanFields().then(this.setPayingUser).catch(e => console.log(e));
  }

  setServerError(value: string) {
    this.serverError = value;
  }
}

export const SchoolContainerModelToken = token<ISchoolContainerModel>('SchoolContainerModelToken');

container.bind(SchoolContainerModelToken).toInstance(SchoolContainerModel).inSingletonScope();

injected(SchoolContainerModel, OrdersServiceStoreToken, UsersServiceStoreToken, CurrenciesServiceStoreToken, AuthModelStoreToken);