import {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useRedirect} from "../../../lib/utils/hooks/useRedirect";
import {Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row} from "reactstrap";

import "./style.scss";
import authIcon from "../../../styles/assets/images/sv/auth.svg";
import {t} from "i18next";
import LanguageSwitch from "../../layout/Header/LanguageSwitch";
import { AUTH_EVENT_TYPE, LANGUAGE_SWITCH } from '../../../lib/utils/constants';
import { CarrotEventTypes } from 'sv-common/constants/carrotEventTypes';
import { useInjection } from 'brandi-react';
import { GlobalModelStoreToken } from '../../../lib/models/global';
import { AuthModelStoreToken } from '../../../entities/auth/model';
import { observer } from 'mobx-react-lite';

const HeaderMenu = () => {
    const globalM = useInjection(GlobalModelStoreToken);
    const authM = useInjection(AuthModelStoreToken);

    const globalState = globalM.get();
    const isRoleTablet = globalState.clientRole === 'tablet';

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const {setRedirect} = useRedirect();
    const {location} = useHistory();

    const onLoginVisible = () => {
      globalM.set({ [AUTH_EVENT_TYPE]: CarrotEventTypes.AUTH_FROM_MENU_EVENT, isLoginPopupOpen: true });
    }

    return <div className="headerMenu">
        <Row g='0'>
            {!isRoleTablet ?
                authM.isLoggedIn() ?
                    <Col xs="6">
                        <Dropdown isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <DropdownToggle
                                tag="span"
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                data-toggle="dropdown"
                                aria-expanded={isDropdownOpen}
                                className="headerMenu_icon"
                            >
                                <img src={authIcon} alt="" />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <Link to="/logout/" className="headerMenu_menuItem"
                                      onClick={() => setRedirect(location.pathname + location.search)}
                                >
                                    <DropdownItem>{t('headerMenu.leave')}</DropdownItem>
                                </Link>
                                <Link target="_blank" to="/me/" className="headerMenu_menuItem">
                                    <DropdownItem>
                                        {t('headerMenu.profile')}
                                    </DropdownItem>
                                </Link>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                :
                    <Col xs="6">
                        <button className="headerMenu_authButton" onClick={onLoginVisible}><img src={authIcon} alt="" /></button>
                    </Col>
            :<></>
            }
            {LANGUAGE_SWITCH &&
                <Col xs={!isRoleTablet ? 6 : ''} className="p-0 px-xl-2 pr-2">
                    <LanguageSwitch />
                </Col>
            }
        </Row>
    </div>
}

export default observer(HeaderMenu);