import React, {useEffect} from 'react';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import {useParams} from "react-router-dom";
import {TDiscount} from "../../../lib/utils/types";
// @ts-ignore
import {currencies as currenciesFallback} from "sv-common/constants/currencies";
import {IArrival} from "../../../lib/utils/types";
import useDiscountCalcEffect from "../useDiscountCalcEffect";
import {useLoaderState} from "../../../lib/api/loaderState";
import {usePartialPay} from "../../../lib/utils/hooks/usePartialPay";
import { useInjection } from 'brandi-react';
import { AdventureContainerModelStoreToken } from './model';
import { GlobalModelStoreToken } from '../../../lib/models/global';
import AdventureContainerUI from './ui';
import { ArrivalModelStoreToken } from '../../../entities/arrivals/model';
import { OrdersModelStoreToken } from '../../../entities/orders/model';
import { observer } from 'mobx-react-lite';

const AdventureContainer = (): JSX.Element => {
    const globalModelState = useInjection(GlobalModelStoreToken);
    const model = useInjection(AdventureContainerModelStoreToken);
    const arrivalM = useInjection(ArrivalModelStoreToken);
    const ordersM = useInjection(OrdersModelStoreToken);

    const globalState = globalModelState.get();
    const {url, arrivalURL = undefined} = useParams<Record<string, string>>();

    const [query, setQuery] = useQueryParams({
      arrival: StringParam,
      step: withDefault(NumberParam, 1),
      orderId: NumberParam,
      people: withDefault(NumberParam, 1),
    });

    const [partPay, setPartPay] = usePartialPay(arrivalM.item, ordersM.item);

    const {setIsLoading} = useLoaderState();

    const resetData = () => setQuery({ orderId: undefined });

    const onArrivalPick = (pickedArrival: IArrival) => model.onArrivalPick(pickedArrival, setQuery, setPartPay);

    const toNextStep = (newData?: [{ [key: string]: any }], orderId?: number) => model.toNextStep(query, setQuery, newData, orderId);

    useEffect(() => {
      model.initial({ query, setQuery, adventureURL: url, arrivalURL, setIsLoading });
    }, []);

    const discount:TDiscount | null = useDiscountCalcEffect(arrivalM.item, model.promocodeApplied, ordersM.item, model.setIsTeamBookActive, model.currencies);

    useEffect(() => {
      query.orderId && model.loadOrder(query.orderId as number);
    }, [query.orderId]);

    useEffect(() => {
        model.onUserChange();
    }, [globalState.userId]);

    return <AdventureContainerUI
      discount={discount}
      resetData={resetData}
      query={query}
      onArrivalPick={onArrivalPick}
      arrivalURL={arrivalURL}
      toNextStep={toNextStep}
      cardProps={model.getBookingCardProps(query, partPay, discount)}
      arrivals={arrivalM.list}
      selectedArrival={arrivalM.item}
      setSelectedArrival={arrivalM.setItem}
    />;
};

export default observer(AdventureContainer);