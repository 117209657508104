import React, {useEffect, useMemo} from "react";
import {NumberParam, useQueryParams, withDefault} from "use-query-params";
import {calculatePrice, calculatePromoAndCertDiscount} from "../../../../lib/utils/helpers";
import { SchoolContainerModelToken } from './model';
import SchoolContainerUI from './ui';
import { observer } from 'mobx-react-lite';
import { useApi } from '../../../../lib/api/useApi';
import { useInjection } from 'brandi-react';
import { GlobalSaleModelToken } from '../../../../lib/models/availablePriceFromSale';

const SchoolContainer = observer(({productType}) => {
    const {getGlobalState} = useApi();
    const globalState = getGlobalState();

    const model = useInjection(SchoolContainerModelToken);
    const globalSaleM = useInjection(GlobalSaleModelToken);

    const [query, setQuery] = useQueryParams({
        step: withDefault(NumberParam, 1),
        people: withDefault(NumberParam, 1),
        orderId: NumberParam,
    });

    const actualPrice = calculatePrice(model.order, model.promoApplied, globalSaleM.calculatePrice(model.selectedGroup, query.people) || model.selectedGroup.price, query.people, null,
        model.certApplied);

    const oldPrice = calculatePrice(null, null, globalSaleM.calculatePrice(model.selectedGroup, query.people) || model.selectedGroup.price, query.people, null,
        null);

    useEffect(() => {
        if (query.people < 1) {
            setQuery({people: 1})
        }
    }, [query.people])

    useEffect(() => {
        query.orderId && model.setDefaultOrder(query.orderId);
    }, [query.orderId]);

    useEffect(() => {
        if (query.people > model.selectedGroup.availableSits) {
            setQuery({people: model.selectedGroup.availableSits});
        }
    }, [model.selectedGroup.id])

    useEffect(() => {
      if (model.order) {
        model.setPromoAndCertDiscount(calculatePromoAndCertDiscount(model.certApplied, model.promoApplied, model.order, model.selectedGroup));
      }
    }, [model.promoApplied?.id, model.certApplied?.id, model.order.id, model.selectedGroup?.id])

    useEffect(() => {
      model.initial({ query, setQuery });
    }, []);

    useEffect(() => {
      model.changePayingUser();
    }, [globalState.userId]);

    const resetData = () => {
        model.setOrder({});
        setQuery({orderId: undefined});
    };

    const bookingCardProps = useMemo(() => (model.selectedGroup ? {
        product: 'group',
        groupType: productType,
        group: model.selectedGroup,
        order: model.order,
        lowerBadge: model.promoAndCertDiscount.percent,
        discountedPrice: actualPrice,
        nonDiscountedPrice: oldPrice,
        priceCurrency: model.currencies.find(c => c.key === model.selectedGroup.currency),
    } : null), [model.selectedGroup?.id, model.order?.id, JSON.stringify(model.currencies), model.promoAndCertDiscount?.percent,
        query.people, actualPrice]);

    return <SchoolContainerUI
      query={query}
      selectedGroup={model.selectedGroup}
      setSelectedGroup={model.setSelectedGroup}
      bookingCardProps={bookingCardProps}
      setQuery={setQuery}
      setPayingUser={model.setPayingUser}
      resetData={resetData}
      payingUser={model.payingUser}
      peopleData={model.peopleData}
      setPeopleData={model.setPeopleData}
      order={model.order}
      setPromocodeApplied={model.setPromoApplied}
      promoAndCertDiscount={model.promoAndCertDiscount}
      setCertificateApplied={model.setCertApplied}
      setOrder={model.setOrder}
      productType={productType}
      serverError={model.serverError}
    />
});

export default SchoolContainer
