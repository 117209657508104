import Steps from '../../../layout/Steps';
import Counter from '../../../generic/Counter/index_2';
import Schedule from './Schedule';
import MembersScreen from './MembersScreen';
import MembersCheckout from './MembersCheckout';
import Final from './Final';
import ProductContainer from '../../../common/ProductContainer';
import React from 'react';

const SchoolContainerUI = (props) => {
  const {
    query,
    selectedGroup,
    setSelectedGroup,
    bookingCardProps,
    setQuery,
    setPayingUser,
    resetData,
    payingUser,
    peopleData,
    setPeopleData,
    order,
    setPromocodeApplied,
    promoAndCertDiscount,
    setCertificateApplied,
    setOrder,
    productType,
    serverError,
  } = props;

  return (
    <ProductContainer productType="group" bookingCardProps={bookingCardProps}>
      <Steps step={query.step} handleStepClick={step => setQuery({step})} resetData={resetData} />
      {query.step === 1 && <>
        {!serverError && <Counter limit={selectedGroup.availableSits}/>}
        <Schedule
          setStep={step => setQuery({step})}
          setSelectedGroup={setSelectedGroup}
          selectedGroup={selectedGroup}
          productType={productType}
          setPayingUser={setPayingUser}
        />
      </>}
      {query.step === 2 && <MembersScreen payingUser={payingUser} peopleData={peopleData}
                                          setPeopleData={setPeopleData} selectedGroup={selectedGroup} /> }

      {query.step === 3 && <MembersCheckout inputMembersData={peopleData}
                                            selectedGroup={selectedGroup}
                                            order={order}
                                            setPromocodeApplied={setPromocodeApplied}
                                            promoAndCertDiscount={promoAndCertDiscount}
                                            setCertificateApplied={setCertificateApplied}
                                            setOrder={setOrder}
      />}
      {query.step === 5 && <Final order={order} productType={productType} />}
    </ProductContainer>
  )
};

export default SchoolContainerUI;