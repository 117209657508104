import './style.scss'
import React, {useState, useEffect} from 'react'
import { normalizeCurrency } from '../../../../lib/utils/helpers';
import PropTypes from 'prop-types'
import { t } from 'i18next';
import ElementWithAccessInCountries from '../../../../lib/utils/hocs/ElementWithAccessInCountries';

const discountLess = 0;
const discountEqual = 1;
const discountRise = 2;

const discountStateHandler = {
  [discountLess]: 'discounts.discountLess',
  [discountEqual]: 'discounts.discountEqual',
  [discountRise]: 'discounts.discountRise',
}

const SubscriptionInfo = ({
    currentSubscriptionData,
    subscription,
    currencies,
    depositSum,
}) => {
    const currentCurrency = normalizeCurrency(currentSubscriptionData?.price_currency, currencies)
    const purchasedCurrency = normalizeCurrency(subscription?.price_currency, currencies)

    const [discountState, setDiscountState] = useState();

    useEffect(() => {
      if (currentSubscriptionData?.luch_individual_discount_size === subscription?.luch_individual_discount_size &&
        currentSubscriptionData?.keel_all_luch_group_discount_size === subscription?.keel_all_luch_group_discount_size &&
        currentSubscriptionData?.race_discount_size === subscription?.race_discount_size) {
        setDiscountState(discountEqual);

        return;
      }
      if (currentSubscriptionData?.luch_individual_discount_size > subscription?.luch_individual_discount_size ||
        currentSubscriptionData?.keel_all_luch_group_discount_size > subscription?.keel_all_luch_group_discount_size ||
        currentSubscriptionData?.race_discount_size > subscription?.race_discount_size) {
        setDiscountState(discountLess);
      } else {
        setDiscountState(discountRise);
      }
    }, [subscription, currentSubscriptionData])

    return (
        <div className={`subscription_info_component ${discountState ===  discountLess ? 'warning' : 'success'}`}>
            <div className="subscription_info_price">
                {t('discounts.discountCurrentOn')} <b>{depositSum}&nbsp;{currentCurrency}</b> {t('discounts.discountWillUpOn')} <b>{subscription?.nominal_value}&nbsp;{purchasedCurrency}.</b>
            </div>
            {!!~discountState && t(discountStateHandler[discountState])}
            <ul>
                <li>
                    {t('discounts.discountOnYachtLuch')} {currentSubscriptionData?.keel_all_luch_group_discount_size}% {'—>'} {subscription?.keel_all_luch_group_discount_size}%
                </li>
                <ElementWithAccessInCountries hideInCountries={['EN']}>
                  <>
                    <li>
                      {t('discounts.discountOnIndividualLuch')} {currentSubscriptionData?.luch_individual_discount_size}% {'—>'} {subscription?.luch_individual_discount_size}%
                    </li>
                    <li>
                      {t('discounts.discountOnRace')} {currentSubscriptionData?.race_discount_size}% {'—>'} {subscription?.race_discount_size}%
                    </li>
                  </>
                </ElementWithAccessInCountries>
                {!!subscription?.cup_discount_size && <ElementWithAccessInCountries hideInCountries={['RU']}>
                  <li>
                    {t('discounts.discountOnRace')} {currentSubscriptionData?.cup_discount_size}% {'—>'} {subscription?.cup_discount_size}%
                  </li>
                </ElementWithAccessInCountries>}
            </ul>
        </div>
    )
}

SubscriptionInfo.propTypes = {
    currentSubscriptionData: PropTypes.object,
    subscription: PropTypes.object,
    currencies: PropTypes.array,
}

export default SubscriptionInfo