import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../../../generic/Divider';
import {useApi} from '../../../../lib/api/useApi';
import dayjs from "dayjs";
import Feedback from "../../../common/Feedback";
import i18next, {t} from "i18next";
import {accusativePlaces, dativePlaces} from "../../../../lib/utils/helpers";
import CalendarButton from '../../../common/CalendarEvents/Button';

const CommonInfoTheory = () => <>
    <br/><br/>
    {t('final.soonWillEmail')}
    <br/><br/>
    {t('final.before')}
    <br/><br/>
    <a href={'https://silavetra.com/magazine/kak-kaifanut-na-yachte'}>{t('final.firstTravel')}</a><br/>
    <a href={'https://silavetra.com/magazine/3-captains'}>{t('final.advices')}</a><br/>
    <a href={'https://silavetra.com/magazine/gid-po-yahtennym-pravam'}>{t('final.aboutYachtLicence')}</a><br/><br/>
    {t('final.ifHaveQuestions')} <br/><br/>
</>

const Final = ({order}) => {
    const {materials} = useApi();
    const {language} = i18next;

    return (
        order
            ? <div className="final">
                {!order.hasPaid
                    ? <div className="final__title">
                        {t('final.thanksForBook', {
                            places: order?.participants?.length,
                            accusativePlaces: accusativePlaces(order?.participants?.length, language),
                            orderName: order?.practice?.name
                        })}
                        {t('final.practiceFrom')}
                        <nobr> {dayjs(order.practice?.startDate).format('DD.MM')}</nobr>{t('final.on')}
                        <nobr> {dayjs(order.practice?.endDate).format('DD.MM')}</nobr>{t('final.inProcess')}
                    </div>
                    :  <div className="final__title">
                        {t('final.successPayment', {places: order.participants?.length,
                            dativePlaces: dativePlaces(order.participants?.length, language), orderName: order.practice?.name})}
                        <nobr> {dayjs(order.practice?.startDate).format('DD.MM')}</nobr>
                        {t('final.on')}
                        <nobr>{dayjs(order.practice?.endDate).format('DD.MM')}</nobr>.
                        <CommonInfoTheory/>
                    </div>
                }
                {order && order.practice && <CalendarButton
                  name={order.practice.name}
                  startDate={order.practice.startDate}
                  endDate={order.practice.endDate}
                  startTime={order.practice.startTime}
                  endTime={order.practice.endTime}
                />}
                {!!materials?.length && <>
                    <div className="final__title">
                        {t('final.materials')}
                    </div>
                    <div className="final__docs-list">
                        {materials.map(material => (
                            <a href={material.link} target="_blank" rel="noopener noreferrer" className="final__docs-item">
                                {material.name}
                            </a>)
                        )}
                    </div>
                </>}
                <Divider/>
                <Feedback />
            </div>
            : <></>
    )
}

Final.propTypes = {
    order: PropTypes.object,
}

export default Final;

