import React, {useEffect, useState} from "react";
import "./style.scss";
import {handleInputChange} from "../../../lib/utils/handleInputChange";
import {useHistory, useLocation} from "react-router-dom";
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import {useLoaderState} from "../../../lib/api/loaderState";
import withOrderProductExistCheck from "../../../lib/utils/hocs/withOrderProductExistCheck";
import {useOrder} from "../../../lib/utils/hooks/useOrder";
import {PRODUCT_TYPES} from "sv-common/constants/certificates";
import { observer } from 'mobx-react-lite';
import { useInjection } from 'brandi-react';
import { AdventureContainerModelStoreToken } from '../AdventureContainer/model';
import { ArrivalModelStoreToken } from '../../../entities/arrivals/model';
import { OrdersModelStoreToken } from '../../../entities/orders/model';
import { AdventureMembersCheckoutModelStoreToken } from './model';
import MembersCheckoutUI from './ui';

const MembersCheckout = ({
    additionalDiscount,
    checkOrderProduct,
}) => {
    const orderM = useInjection(OrdersModelStoreToken);
    const arrivalM = useInjection(ArrivalModelStoreToken);
    const model = useInjection(AdventureContainerModelStoreToken);
    const checkoutModel = useInjection(AdventureMembersCheckoutModelStoreToken);

    const inputMembersData = model.peopleData;
    const arrival = arrivalM.item;
    const promocodeApplied = model.promocodeApplied;
    const certificateApplied = model.certificateApplied;
    const promoAndCertDiscount = model.promoAndCertDiscount;
    const order = orderM.item;

    const [step] = useQueryParam("step", withDefault(NumberParam, 1));

    const [membersData, setMembersData] = useState([]);
    const [promocodeInput, setPromocodeInput] = useState('');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [invalidPromocodeReason, setInvalidPromocodeReason] = useState('');

    const history = useHistory();

    const {pathname} = useLocation();

    const {setIsLoading} = useLoaderState();

    const promocodeChange = e => setPromocodeInput(e.target.value);

    useEffect(() => {
        inputMembersData.length && setMembersData(() => inputMembersData);
    }, []);

    useEffect(() => {
      checkoutModel.onOrderChange(verifyPromocode);
    }, [order?.id]);

    useEffect(() => {
      order && model.loadOrder(order.id);
    }, [model.promocodeApplied?.id, model.certificateApplied?.id, order?.id]);

    const handleChange = (e) => {
        const {name, value} = handleInputChange(e);
        checkoutModel.setCurrentData({ [name]: value });
    };

    const {setPromocodeOrCertificate} = useOrder();

    const handleUpdate = () => checkoutModel.handleUpdate(setIsLoading, step, history, pathname)
      .catch(e => {
        checkOrderProduct(e, '/travel');
      });

    const onSetOrder = (cb) => {
      orderM.setItem(cb(order));
    }

    const verifyPromocode = (value, type) =>
        setPromocodeOrCertificate(PRODUCT_TYPES.ARRIVALS, value, arrival?.id, order?.id, (getData) => checkoutModel.setCurrentData(getData()),
            model.setPromocodeApplied, model.setCertificateApplied, onSetOrder, additionalDiscount, setInvalidPromocodeReason,
            setPromocodeInput, model.resetPromoStates, type)

    return <MembersCheckoutUI
      arrival={arrival}
      order={order}
      elapsedTime={orderM.elapsedTime}
      setIsSubmitDisabled={setIsSubmitDisabled}
      membersData={membersData}
      promocodeInput={promocodeInput}
      promocodeApplied={promocodeApplied}
      verifyPromocode={verifyPromocode}
      promocodeChange={promocodeChange}
      invalidPromocodeReason={invalidPromocodeReason}
      certificateApplied={certificateApplied}
      promoAndCertDiscount={promoAndCertDiscount}
      currentData={checkoutModel.currentData}
      isSubmitDisabled={isSubmitDisabled}
      handleUpdate={handleUpdate}
      handleChange={handleChange}
      setPromocodeApplied={model.setPromocodeApplied}
      setCertificateApplied={model.setCertificateApplied}
    />
};

export default withOrderProductExistCheck(observer(MembersCheckout));

