import React, {Dispatch, FunctionComponent, Ref, SetStateAction, useEffect} from "react";
import currency from "currency.js";
import {TSubscription} from "../../../../lib/api/useTypedPublicEndpoint";
import {ICurrency} from "../../../../lib/utils/types";
import { t } from 'i18next';
import ElementWithAccessInCountries from '../../../../lib/utils/hocs/ElementWithAccessInCountries';
// @ts-ignore
import getCountryValue from '../../../../lib/utils/getCountryValue';

type TRenderSliderProps = {
    handleChange: (num: number) => void
    min: undefined | number
    max: undefined | number

}

type TProps = {
    subscriptions: undefined | Array<TSubscription>
    subscription: undefined | TSubscription
    register?: (s: { required: boolean }) => Ref<HTMLInputElement | HTMLTextAreaElement> | undefined
    currencies: Array<ICurrency> | undefined
    setSubscription: (s: TSubscription) => void | Dispatch<SetStateAction<undefined|TSubscription>>
    renderSlider: (props: TRenderSliderProps) => React.ReactNode
    currentValue?: number;
}

const SubscriptionRange: FunctionComponent<TProps> = ({subscriptions, subscription, renderSlider, currencies, setSubscription, currentValue}) => {
    const min = subscriptions && +subscriptions[0].price_value
    const max = subscriptions && +subscriptions[subscriptions.length - 1].price_value
    const currencySign = currencies?.length && subscription?.nominal_currency
            && currencies.find((item: ICurrency) => item.key === subscription?.price_currency)?.sign

    const handleChange = (num: number) => {
        if (!subscriptions) return;
        const closestSubscription = subscriptions.reduce((a: TSubscription, b: TSubscription) => {
            return Math.abs(currency(b.price_value).subtract(num).value) < Math.abs(currency(a.price_value).subtract(num).value) ? b : a;
        });
        setSubscription(closestSubscription);
    }

    return <>
        <div className={'range-block-wrapper'} style={{'--range-fill-current': `${(currentValue || 1)/(max || 100000) * 100}%`} as React.CSSProperties }>
            <div className={'control-wrapper range-block'}>
            <span className={'input-label'}>
                {t('subscriptions.amountOfAbonement')}:
            </span>
                {renderSlider({handleChange, min, max})}
                {subscriptions &&
                        <>
                            <div className="range-minimum">{min}</div>
                            <div className="range-maximum">{max}</div>
                        </>
                }
                <span className={"error-label d-none"}></span>
            </div>
        </div>

        <p className="subscription-text">
            {t('discounts.discountOnYachtLuch')} <span>{subscription?.keel_all_luch_group_discount_size}%</span>
        </p>
        <ElementWithAccessInCountries hideInCountries={['EN']}>
          <p className="subscription-text">
              {t('discounts.discountOnIndividualLuch')} <span>{subscription?.luch_individual_discount_size}%</span>
          </p>
        </ElementWithAccessInCountries>
        {!!(getCountryValue({ en: subscription?.cup_discount_size, ru: subscription?.race_discount_size })) && <p className="subscription-text">
            {t('discounts.discountOnRace')}
            <span> {getCountryValue({ en: subscription?.cup_discount_size, ru: subscription?.race_discount_size }) || 0}%</span>
        </p>}
        <div className="subscription-text"> {t('subscriptions.nominal')} <span>{subscription?.nominal_value} {currencySign}</span>
        </div>
    </>
}

export default SubscriptionRange