import {
  IArrival,
  ICertificate,
  ICurrency,
  IPromocode,
  TDiscount,
  TOrder,
  TPromoAndCertDiscount,
} from '../../../lib/utils/types';
import { TUserFields } from '../../../lib/api/useTypedPublicEndpoint';
import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { CurrenciesServiceStoreToken, ICurrenciesService } from '../../../entities/currencies/api/service';
import { IUsersService, UsersServiceStoreToken } from '../../../entities/users/api/service';
import { ExperienceServiceStoreToken, IExperienceService } from '../../../entities/experience/api/service';
import { AuthModelStoreToken, IAuthModel } from '../../../entities/auth/model';
import { GlobalModelStoreToken, IGlobalStateModel } from '../../../lib/models/global';
import { DecodedValueMap, QueryParamConfigMap } from 'serialize-query-params';
import { SetQuery } from 'use-query-params/lib/types';
import { calculatePrice, calculatePromoAndCertDiscount } from '../../../lib/utils/helpers';
import { getPartPayPrice } from '../../../lib/utils/helpers/priceHelpers';
import { t } from 'i18next';
import { flow, makeAutoObservable } from 'mobx';
import { ArrivalModelStoreToken, IArrivalModel } from '../../../entities/arrivals/model';
import { IOrdersModel, OrdersModelStoreToken } from '../../../entities/orders/model';
import { GlobalSaleModelToken, IGlobalSaleModel } from '../../../lib/models/availablePriceFromSale';
import getCountryPartialPaymentMarkup from '../../../lib/utils/getPartialPaymentMarkup';

interface IInitialProps {
  query: DecodedValueMap<QueryParamConfigMap>,
  setQuery: SetQuery<QueryParamConfigMap>,
  adventureURL: string,
  arrivalURL?: string,
  setIsLoading: (v: boolean) => void,
}

interface IAdventureContainerModel {
  defaultValues?: TUserFields,
  currencies?: ICurrency[],
  promocodeApplied?: IPromocode,
  isTeamBookActive: boolean,
  peopleData: { [key: string]: any }[],
  promoAndCertDiscount: TPromoAndCertDiscount,
  certificateApplied?: ICertificate,

  setDefaultValues: (value: TUserFields) => void,
  setCurrencies: (value: ICurrency[]) => void,
  setPromocodeApplied: (value: IPromocode) => void,
  setIsTeamBookActive: (value: boolean) => void,
  setPeopleData: (value: { [key: string]: any }[]) => void,
  setPromoAndCertDiscount: (value: TPromoAndCertDiscount) => void,
  setCertificateApplied: (value: ICertificate) => void,
  setArrivalData: (arrival: IArrival) => void,
  resetPromoStates: (type: string) => void,

  initial: (props: IInitialProps) => void,
  loadOrder: (orderId: number) => void,
  isPromocodeApplied: () => boolean,
  isCertificateApplied: () => boolean,
  onUserChange: () => void,
  getBookingCardProps: (query: DecodedValueMap<QueryParamConfigMap>, partPay: number, discount: TDiscount | null) => Record<any, any> | null,
  onArrivalPick: (pickedArrival: IArrival, setQuery: SetQuery<QueryParamConfigMap>, setPartPay: (v: number) => void) => void,
  toNextStep: (query: DecodedValueMap<QueryParamConfigMap>, setQuery: SetQuery<QueryParamConfigMap>, newData?: [{ [key: string]: any }], orderId?: number) => void,
}

export default class AdventureContainerModel implements IAdventureContainerModel {
  defaultValues?: TUserFields;
  currencies?: ICurrency[] = [];
  promocodeApplied?: IPromocode;
  isTeamBookActive = false;
  peopleData: { [key: string]: any }[] = [];
  promoAndCertDiscount: TPromoAndCertDiscount = {} as TPromoAndCertDiscount;
  certificateApplied?: ICertificate;

  constructor(
    private arrivalsM: IArrivalModel,
    private currenciesS: ICurrenciesService,
    private usersS: IUsersService,
    private experienceS: IExperienceService,
    private authM: IAuthModel,
    private globalM: IGlobalStateModel,
    private ordersM: IOrdersModel,
    private globalSaleM: IGlobalSaleModel,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  initial = flow<void, [IInitialProps]>(function* (this: AdventureContainerModel, { query, setQuery, adventureURL, arrivalURL, setIsLoading }) {
    setIsLoading(true);
    if (this.authM.isLoggedIn()) {
      const res = yield this.usersS.getCleanFields();
      this.setDefaultValues(res);
    } else {
      setQuery({ orderId: undefined, step: 1 });
    }

    const orderId = query.orderId;

    if (orderId) {
      const res = yield this.ordersM.get(orderId);
      setQuery({ people: res.paidForLength });
      this.setPeopleData(res.participants);
    } else if (query.step > 2) setQuery({ step: 1 });

    if (arrivalURL) {
      const res = yield this.arrivalsM.getByUrl(arrivalURL);
      this.setArrivalData(res);
    } else {
      const res: {arrivals: IArrival[], status: string} = yield this.arrivalsM.listByAdventureUrl(adventureURL);
      this.globalM.set({materials: res.arrivals?.[0]?.extra_materials});
      const pickedArrival: IArrival | undefined = res.arrivals?.find(arr => arr.url === query.arrival);
      if (pickedArrival) this.arrivalsM.setItem(pickedArrival);
    }
    const currencies = yield this.currenciesS.get();
    this.setCurrencies(currencies);

    const levels = yield this.experienceS.getDifficultyLevels();
    this.globalM.set({levels});
    setIsLoading(false);
  });

  setDefaultValues(value: TUserFields) {
    this.defaultValues = value;
  }

  setCurrencies(value: ICurrency[]) {
    this.currencies = value;
  }

  setPromocodeApplied(value?: IPromocode) {
    this.promocodeApplied = value;
  }

  setIsTeamBookActive(value: boolean) {
    this.isTeamBookActive = value;
  }

  setPeopleData(value: { [key: string]: any }[]) {
    this.peopleData = value;
  }

  setPromoAndCertDiscount(value: TPromoAndCertDiscount) {
    this.promoAndCertDiscount = value;
  }

  setCertificateApplied(value?: ICertificate) {
    this.certificateApplied = value;
  }

  setArrivalData(arrival: IArrival) {
    this.arrivalsM.setItem(arrival);
    this.globalM.set({ materials: arrival?.extra_materials })
  }

  toNextStep(query: DecodedValueMap<QueryParamConfigMap>, setQuery: SetQuery<QueryParamConfigMap>, newData?: [{ [key: string]: any }], orderId?: number) {
    if (query.step === 1) {
      this.authM.ensureLoggedIn((isUserAlreadyLoggedIn: boolean, user?: TUserFields) => {
        !isUserAlreadyLoggedIn && user && this.setDefaultValues(user);
        setQuery({ step: query.step + 1 });
      })
    } else {
      setQuery({ step: query.step + 1 });
    }

    if (query.step === 2 && newData) {
      this.setPeopleData(newData);
      setQuery({ step: 3, orderId });
    }
  }

  getArrivalPriceValue(partPay: number, people: number) {
    return (this.globalSaleM.calculatePrice(this.arrivalsM.item, people) || this.arrivalsM.item?.price_value || 0) * getCountryPartialPaymentMarkup(partPay);
  }

  resetPromoStates(type: string) {
    if (type === 'promocodeApplied') {
      this.setPromocodeApplied(undefined);
    }
    if (type === 'certificateApplied') {
      this.setCertificateApplied(undefined);
    }
  }

  loadOrder(orderId: number) {
    if (orderId !== this.ordersM.item?.id) {
      this.ordersM.get(orderId).then(res => {
        this.setAndCalculateDiscount(res);
      })
    } else {
      this.ordersM.item && this.setAndCalculateDiscount(this.ordersM.item);
    }
  }

  isPromocodeApplied(): boolean {
    return this.promocodeApplied?.status === "valid" && this.promocodeApplied.extraInfo === 'promocode' || !!this.ordersM.item?.promocodeAppliedDiscount;
  }

  isCertificateApplied(): boolean {
    return this.certificateApplied?.status === 'valid' || !!this.ordersM.item?.certificateApplied;
  }

  onUserChange() {
    this.usersS.getCleanFields()
      .then(this.setDefaultValues)
      .catch(e => console.log(e))
  }

  getBookingCardProps(query: DecodedValueMap<QueryParamConfigMap>, partPay: number, discount: TDiscount | null): Record<any, any> | null {
    const { levels } = this.globalM.get();

    const teamBookDiscountCondition = this.arrivalsM.item?.team_book_discount_condition;
    const teamBookDiscountSize = this.arrivalsM.item?.team_book_discount_size;

    if (this.arrivalsM.item) {
      return {
        product: 'travel',
        arrival: this.arrivalsM.item,
        levels,
        order: this.ordersM.item,
        priceCurrency: this.currencies?.find(c => c.key === this.arrivalsM.item?.price_currency),
        upperBadge: discount,
        discountedPrice: this.getActualPrice(query, partPay, discount, query.people),
        extraDiscountLabel: teamBookDiscountSize && query.people >= (teamBookDiscountCondition || 0)
          ? t('discounts.people', {peopleCount: teamBookDiscountCondition})
          : '',
        lowerBadge: (this.isPromocodeApplied() || this.isCertificateApplied()) ? this.promoAndCertDiscount?.percent : undefined,
        nonDiscountedPrice: calculatePrice(null, null, this.getArrivalPriceValue(partPay, query.people), query.people, null, null),
      }
    } else {
      return null;
    }
  }

  onArrivalPick(pickedArrival: IArrival, setQuery: SetQuery<QueryParamConfigMap>, setPartPay: (v: number) => void) {
    this.arrivalsM.setItem(pickedArrival);
    setQuery({ arrival: pickedArrival.url });
    setPartPay(100);
  }

  private setAndCalculateDiscount(order: TOrder) {
    this.setPromoAndCertDiscount(calculatePromoAndCertDiscount(
      this.certificateApplied,
      this.promocodeApplied,
      order
    ));
  }

  private getActualPrice(query: DecodedValueMap<QueryParamConfigMap>, partPay: number, discount: TDiscount | null, people: number) {
    return getPartPayPrice(calculatePrice(this.ordersM.item, this.promocodeApplied, this.getArrivalPriceValue(partPay, people), query.people, discount?.value, this.certificateApplied), partPay);
  }
}

export const AdventureContainerModelStoreToken = token<IAdventureContainerModel>('AdventureContainerModelStoreToken');

container.bind(AdventureContainerModelStoreToken).toInstance(AdventureContainerModel).inSingletonScope();

injected(
  AdventureContainerModel,
  ArrivalModelStoreToken,
  CurrenciesServiceStoreToken,
  UsersServiceStoreToken,
  ExperienceServiceStoreToken,
  AuthModelStoreToken,
  GlobalModelStoreToken,
  OrdersModelStoreToken,
  GlobalSaleModelToken,
);