import React, {useCallback, useEffect, useState} from "react";
import {Col, Row, Input, Label} from 'reactstrap';
import { useForm } from "react-hook-form";
import valid from '../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../styles/assets/images/sv/invalid.svg';
import './style.scss'
import {regexes} from '../../../lib/utils/helpers';
import {useHistory, useLocation} from "react-router-dom";
import FormButton from "../../generic/buttons/main/index2";
import SubscriptionInfo from './SubscriptionsInfo'
import _ from 'lodash'
import {features} from "../../../lib/api/globalState";
// @ts-ignore
import {PRODUCT_TYPES} from "sv-common/constants/certificates";
import {TSubscription, useTypedPublicEndpoints} from "../../../lib/api/useTypedPublicEndpoint";
import {useOrdersApi} from "../../../lib/api/useOrdersApi";
import {usePaymentsApi} from "../../../lib/api/usePaymentsApi";
import {TCertificate, useCertificatesApi} from "../../../lib/api/useCertificatesApi";
import useCountry from "../../../lib/utils/hooks/useCountry";
import {ICertificate, ICurrency, IPromocode, TOrder, TPromoAndCertDiscount} from "../../../lib/utils/types";
import {useLoaderState} from "../../../lib/api/loaderState";
import external from "../../../styles/assets/images/sv/external.svg";
import i18next from "i18next";
import Divider from "../../generic/Divider";
import SubscriptionRange from "./SubscriptionRange";
import ElementWithAccessInCountries from '../../../lib/utils/hocs/ElementWithAccessInCountries';
import {useInjection} from 'brandi-react';
import {AuthModelStoreToken} from '../../../entities/auth/model';
import {getCookie} from '../../../lib/utils/cookies';
import PaymentMethodCertComponent from "../../common/PayBlock/paymentMethods/cert";
import {setInvalidReason} from "../../common/PayBlock";
import {useOrder} from "../../../lib/utils/hooks/useOrder";
import {PayBlockModelStoreToken} from "../../common/PayBlock/models/PayBlockModel/index.model";
import {observer} from "mobx-react-lite";
import {GlobalSettingsModelStoreToken} from "../../../entities/globalConfig/model";

type TSubscriptionForm = {
    setSubscription: (s: TSubscription) => void,
    certificateApplied: TCertificate,
    setCertificateApplied: (c: ICertificate | undefined | null) => void,
    setPromoApplied: (c: IPromocode | undefined | null) => void,
    email: string,
    subscription: TSubscription,
    price: number,
    userSubscriptionId: number,
    currencies: [ICurrency],
    entity: { [key: string]: any },
    order: TOrder,
    depositSum: number,
    promoAndCertDiscount: TPromoAndCertDiscount,
    promoApplied: IPromocode,
}

type TSubscriptionFormWithCountry = TSubscriptionForm & { country: string }

const SubscriptionFormWithCountry = (props: TSubscriptionForm) => {
    const country = useCountry();
    return <SubscriptionForm {...props} country={country}/>
}

const SubscriptionForm = observer(({
                                       setSubscription, certificateApplied, setCertificateApplied, country,
                                       email, subscription, price, userSubscriptionId, currencies, entity, order,
                                       depositSum, setPromoApplied, promoAndCertDiscount, promoApplied,
                                   }: TSubscriptionFormWithCountry) => {
    const {t, language} = i18next;

    const payBlockModel = useInjection(PayBlockModelStoreToken);
    const authM = useInjection(AuthModelStoreToken);
    const globalSettings = useInjection(GlobalSettingsModelStoreToken);
    const {checkCertificate} = useCertificatesApi();
    const {getSubscriptions} = useTypedPublicEndpoints();
    const {createOrder} = useOrdersApi();
    const {createPayment} = usePaymentsApi();
    // @ts-ignore
    const {pathname, query} = useLocation();
    const history = useHistory();
    const {setPromocodeOrCertificate} = useOrder();

    const [responseError, setResponseError] = useState<string>();
    const [subscriptions, setSubscriptions] = useState<TSubscription[] | undefined>(undefined);
    const [allSubscriptions, setAllSubscriptions] = useState<TSubscription[] | null>(null);
    const [currentSubscriptionData, setCurrentSubscriptionData] = useState<{ [key: string]: any } | undefined>({})

    const {setIsLoading} = useLoaderState();

    useEffect(() => {
        setIsLoading(true);
        getSubscriptions()
                .then(res => {
                    const {subscriptions} = res;
                    const subscriptionsFiltered = subscriptions.filter(subscription => subscription.is_available_for_sale)

                    setSubscriptions(subscriptionsFiltered);

                    setAllSubscriptions(subscriptions)
                    if (price) {
                        const chosenSubscription = subscriptionsFiltered.find(item => +item.price_value === +price)
                        if (!chosenSubscription) return;
                        setSubscription(chosenSubscription);
                    } else {
                        setSubscription(subscriptionsFiltered[0])
                    }
                })
                .finally(() => setIsLoading(false))
    }, [country])

    const {register, errors, handleSubmit} = useForm({mode: "onBlur", reValidateMode: "onChange"});

    const paymentHandler = (orderId: number) => {
        const redirectURL = query.redirectURL ? `&redirectURL=${query.redirectURL}` : '';
        const redirectOrderId = query.redirectURL ? `&redirectOrderId=${query.redirectOrderId}` : '';
        const paymentQueryParams = `?orderId=${orderId}&step=2${redirectURL}${redirectOrderId}`;

        createPayment(orderId, pathname + paymentQueryParams)
                .then((res) => (window.location.href = res.url))
                .catch((e) => {
                    console.log(e);
                })
    };

    const onCreate = (data: { email: string }) => {
        authM.ensureLoggedIn(() => {
            setIsLoading(true)
            const participant = {email: data.email};
            const payloadData = {
                ...order,
                participants: [participant],
                product: 'subscriptions',
                productId: subscription.id,
                ym_client_id: getCookie(`_ym_uid`),
                certificateApplied: certificateApplied?.id,
                promocodeApplied: promoApplied?.id,
            };
            return createOrder(payloadData)
                    .then(res => {
                        if (features['SV-1569']) {
                            history.push('/error')
                        } else {
                            paymentHandler(res.orderId)
                        }
                    }).catch(res => {
                        setResponseError(res.response.data.message)
                    })
                    .finally(() => setIsLoading(false))
        })
    }

    const verifyPromocode = useCallback((value: string, type?: string, captcha?: string) => {
        if (entity?.id) {
            setPromocodeOrCertificate(
                    PRODUCT_TYPES.SUBSCRIPTIONS,
                    value,
                    entity.id,
                    0,
                    undefined,
                    onSetPromo,
                    onSetCertificate,
                    undefined,
                    undefined,
                    (reason: string, type: string) => setInvalidReason(reason, type, payBlockModel),
                    undefined,
                    payBlockModel.resetAppliedDiscounts,
                    type,
                    captcha,
            );
        }
    }, [entity?.id, payBlockModel, order, setCertificateApplied]);


    useEffect(() => {
        allSubscriptions && setCurrentSubscriptionData(allSubscriptions.find( subscription => subscription.id === userSubscriptionId ))
    }, [subscriptions, userSubscriptionId])

    const onSetPromo = (v: IPromocode = {} as IPromocode) => {
        payBlockModel.promo.setValue(v);
        payBlockModel.promo.setQuery(v.name);
        setPromoApplied(v);
    }

    const onSetCertificate = (v: ICertificate = {} as ICertificate) => {
        payBlockModel.cert.setValue(v);
        payBlockModel.cert.setQuery(v.name);
        setCertificateApplied(v);
    }

    return (
        // @ts-ignore
		<div className="subscription">
			<p className="subscription-title">{t('subscriptions.title')}</p>
            <form onSubmit={handleSubmit(onCreate)}>
                <Row>
                    <Col xs={12} md={6} className={'control-wrapper'}>
                    <span className={'input-label'}>
                        {t('subscriptions.countryOfUsage')}:
                    </span>
                        <ElementWithAccessInCountries hideInCountries={['EN']}>
                            <select id='country' defaultValue={'RU'} value={country} disabled>
                                <option value="RU">Россия</option>
                            </select>
                        </ElementWithAccessInCountries>
                        <ElementWithAccessInCountries hideInCountries={['RU']}>
                            <select id='country' defaultValue={'EN'} value={country} disabled>
                                <option value="EN">UAE</option>
                            </select>
                        </ElementWithAccessInCountries>
                    </Col>
                    <Col xs={12} md={6} className={'control-wrapper'}>
                    <span className={'input-label'}>
                        {t('subscriptions.ownerEmail')}
                    </span>
                        {(errors[`email`]) && <span><img src={errors[`email`] ? invalid : valid} alt=""/></span>}

                        <Input type="text" id={'email'} name={'email'} placeholder="username@email.com"
                               defaultValue={email}
                               innerRef={register({required: true, pattern: regexes.emailRegex})}
                        />

                        <span className={"error-label d-none"}>
                    </span>
                    </Col>
                </Row>
                <SubscriptionRange
                        subscriptions={subscriptions}
                        subscription={subscription}
                        setSubscription={setSubscription}
                        register={register}
                        currencies={currencies}
                        renderSlider={({handleChange, min, max}) =>
                                <Input
                                        type="range"
                                        id={'price'}
                                        value={subscription?.price_value}
                                        name={'value'}
                                        min={min}
                                        max={max}
                                        step={1}
                                        onChange={(e) => handleChange(+e.target.value)}
                                        innerRef={register && register({required: false})}
                                />}/>
                {!_.isEmpty(currentSubscriptionData) &&
                        <SubscriptionInfo
                                currentSubscriptionData={currentSubscriptionData}
                                subscription={subscription}
                                currencies={currencies}
                                depositSum={depositSum}
                        />
                }
                <div className='checkout__input-wrapper'>
                    <div className='payment-methods-block'>
                        <PaymentMethodCertComponent
                            order={order}
                            isShowPromocode={globalSettings.config['promo_subscription_available']}
                            promoAndCertDiscount={promoAndCertDiscount}
                            verifyPromocode={verifyPromocode}
                            setCertificateApplied={onSetCertificate}
                            setPromocodeApplied={onSetPromo}
                            certRequired={false}
                        />
                    </div>
                </div>
                <Divider/>
                <div className="checkout__input-wrapper radio-input">
                    <Input
                            type="checkbox"
                            id="publicOfferConsent"
                            name="publicOfferConsent"
                            innerRef={register({
                                required: true,
                            })}
                    />
                    <Label htmlFor="publicOfferConsent">
                        <a href={t('subscriptions.publicOffer') as string} target="_blank"
                           rel="noopener noreferrer">
                            {t('inputs.consents.publicOffer')}&nbsp;<img src={external} alt=""/>
                        </a>
                    </Label>
                    <div className={"note-label"}>{t("inputs.consents.publicOfferNote")}</div>

                    <span className={errors.publicOfferConsent ? "error-label" : "error-label d-none"}>
                    {errors.publicOfferConsent?.type === "required" && t("inputs.consents.publicOfferError")}
                </span>
                </div>
                <div className="d-flex align-items-center flex-column">
                    <FormButton value={t('common.buy')} type="submit"/>
                    {responseError && <small className="text-danger">{responseError}</small>}
                </div>
            </form>
        </div>
);
});

export default SubscriptionFormWithCountry;
