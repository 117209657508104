import React from "react";
import './style.scss';
import CustomDiscount from "../../common/badges/CustomDiscount";
import PriceBlock from "../PriceBlock";
import ElementWithAccessInCountries from '../../../lib/utils/hocs/ElementWithAccessInCountries';
import { t } from 'i18next';

const BookingCard = ({ entity, hasPaid, payingUser, currencies, oldPrice, price}) => {


    return (
        <>
            <div className="booking-card subscription-card">
                <div className="primary-info">
                    <div className="card-hole"></div>
                    <div className="main-info-label card-label"></div>
                    <div className="card-course card-caption">{t('subscriptions.headerTitle')}</div>
                    {!!entity?.keel_all_luch_group_discount_size &&
                        <>
                            <CustomDiscount type={'keel'} value={entity.keel_all_luch_group_discount_size} />
                            <CustomDiscount type={'group'} value={entity.keel_all_luch_group_discount_size} />
                        </>
                    }
                    {!!entity?.luch_individual_discount_size &&
                        <CustomDiscount type={'individual'} value={entity.luch_individual_discount_size} />
                    }
                    {!!(entity?.cup_discount_size || entity?.race_discount_size) &&
                        <CustomDiscount type={'race'} value={entity.race_discount_size || entity?.cup_discount_size} />
                    }
                </div>
                <div className="secondary-info row no-gutters">
                    <ElementWithAccessInCountries hideInCountries={['EN']}>
                      <div className="col-7 field">
                        <div className="difficulty__label card-label">Тип абонемента</div>
                        <div className="card-caption">
                          Парусный спорт
                        </div>
                      </div>
                    </ElementWithAccessInCountries>
                    <div className="col-7 field">
                        <div className="time__label card-label">{t('subscriptions.name')} {payingUser?.last_name && <span>{t('subscriptions.andSurname')}</span>} </div>
                        {payingUser?.first_name && 
                            <div className="time__value card-caption">
                                {`${payingUser.first_name} ${payingUser?.last_name}`}
                            </div>
                        }
                        
                    </div>
                    <div className="col-7 field">
                        <div className="time__label card-label">{t('subscriptions.ownerEmail')}</div>
                        <div className="time__value card-caption">
                            {payingUser?.email}
                        </div>
                    </div>
                </div>
                <PriceBlock
                    hasPaid={hasPaid}
                    entity={entity}
                    currencies={currencies}
                    oldPrice={oldPrice}
                    price={price}
                />
            </div>
        </>
    );
};



export default BookingCard;
