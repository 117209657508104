import { t } from 'i18next';
import { accusativePlaces } from '../../../../../../lib/utils/helpers';
import React from 'react';
import dayjs from 'dayjs';

const Dates = ({order}) => <><nobr> {dayjs(order.groupId?.startDate).format('DD.MM')}</nobr>
  {t('final.schoolTo')} <nobr>{dayjs(order.groupId?.endDate).format('DD.MM')}</nobr></>

const CommonInfoTheory = () => <>
  <br/><br/>
  {t('final.soonWillEmail')}
  <br/><br/>
  {t('final.before')}
  <br/><br/>
  <a href={'https://silavetra.com/magazine/gid-po-yahtennym-pravam'}>{t('final.aboutYachtLicence')}</a><br/>
  <a href={'https://silavetra.com/rukovodstvo-nachinaushim-kapitanam'}>{t('final.howGetLicense')}</a><br/>
  <a href={'https://silavetra.com/magazine/molodye-yachtsmeny-shishkin'}>{t('final.youngYachtMen')}</a><br/><br/>
  {t('final.ifHaveQuestions')}<br/><br/>
</>

const useTheory = (setTitle, setUnpaidTitle, order) => {
  const handle = () => {
    setTitle(() => <>
      {t('final.successTheory')} {order.groupId?.isOnline ? t('final.online') : t('final.offline')}
      {t('final.from')} <Dates order={order}/>.
      <CommonInfoTheory/>

    </>)
    setUnpaidTitle(() => <>
        {t('final.thanksForBook', {order: order.participants?.length, places: accusativePlaces(order.participants?.length),
          orderName: 'на курс теории'})}
        {order.groupId?.isOnline ? t('final.online') : t('final.offline')} {t('final.practiceFrom')}
        <Dates order={order}/> {t('final.beingProcessing')}
        <CommonInfoTheory/>
      </>
    )
  }

  return { handle };
}

export default useTheory;