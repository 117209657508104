import React from 'react';
import { Col, Row } from 'reactstrap';
import Divider from '../../../../generic/Divider';
import PropTypes from "prop-types";
import PaymentTimer from "../../../../common/PaymentTimer";
import i18next from "i18next";

const OrderInfo = ({order, practice, elapsedTime, setIsSubmitDisabled}) => {
    const {t, language} = i18next;

    return (
        <div className={"checkout__main-info"}>
            <div className="title-label">{t('travel.arrivalDate')}</div>
            <div className="checkout__date">{
                (practice?.startDate && practice?.endDate)
                    ? (<>
                        {new Date(practice?.startDate).toLocaleString(language, { month: "long", day: "numeric" }) || ''}{" "}
                        - {new Date(practice?.endDate).toLocaleString(language, { month: "long", day: "numeric" }) || ''}
                    </>) 
                    : <div style={{height: '24px', marginBottom: '6px'}} />
                }
            </div>
            {!order?.hasPaid && elapsedTime !== null &&
                <PaymentTimer elapsedTime={elapsedTime} className="pl-0 pt-1 pr-1"
                              setIsSubmitDisabled={setIsSubmitDisabled}
                />
            }
            <Divider />
            <div className="checkout__description">{practice?.name}</div>

            <Row className={"no-gutters"}>
                <Col xs={12} sm={6}>
                    <div className="title-label">{t('travel.include')}</div>
                    <ul className="items-list">
                        {practice?.optionsIncluded?.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </Col>
                <Col xs={12} sm={6} className={"pl-2"}>
                    <div className="title-label">{t('travel.notInclude')}</div>
                    <ul className="items-list">
                        {practice?.optionsNotIncluded?.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

OrderInfo.propTypes = {
    order: PropTypes.object,
    practice: PropTypes.object,
    elapsedTime: PropTypes.number,
    setIsSubmitDisabled: PropTypes.func,
};

export default OrderInfo;
